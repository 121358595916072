import React, { useState } from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import {
  HeroSection,
  BannerSubtitle1Container,
  BannerTitleContainer,
  BannerSubtitle2Container,
  Wrapper,
  Line,
  Line2,
} from "../components/Banner"

import { RedRectangularButton } from "../components/Buttons"
import Sidebar from "../components/Sidebar"
import { InteriorContent } from "../components/InteriorContent"
import Img from "gatsby-image"
import underline from "../images/underline.png"
import VideoPopup from "../components/VideoPopup"
import WhatOurClientsAreSaying from "../components/WhatOurClientsAreSaying"
import Maps from "../components/Maps"
import {
  ContentSidebarContainer,
  LineBottom,
  HeaderSection,
} from "../components/PageElements"
import favicon from "../images/favicon.png"
import { Global, css } from "@emotion/core"

const PageTemplate = ({ data, pageContext }) => {
  const cheerio = require("cheerio")
  const page = data.wpgraphql.page

  const $ = cheerio.load(`${page.content}`)
  const bannerSubtitle1 = $(".banner-subtitle-1").text()
  const bannerTitle = $(".banner-title").text()
  const bannerSubtitle2 = $(".banner-subtitle-2").text()
  const h3 = $("h3").text()
  const h1 = $("h1").text()
  const firstParagraph = $("p:nth-of-type(3)").text()
  const restOfContent = $(`${page.content}`).not(
    `.banner-subtitle-1, .banner-title, .banner-subtitle-2, h3, h1,  .sc-bwzfXH.row.uTxCW`
  )

  const breakpoints = useBreakpoint()

  const [toggler, setToggler] = useState(false)

  const {
    title,
    metaDesc,
    opengraphTitle,
    twitterDescription,
    twitterTitle,
  } = pageContext.seo

  return (
    <Layout>
      <Global
        styles={css`
          ${InteriorContent} div:nth-of-type(3) p:nth-of-type(1) {
            display: none;
          }
          ${InteriorContent} div.content p {
            display: block !important;
          }
        `}
      />
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{opengraphTitle ? opengraphTitle : title}</title>
        <meta name="description" content={metaDesc ? metaDesc : ""} />
        {pageContext.seo.opengraphImage !== null && (
          <meta
            name="image"
            content={pageContext.seo.opengraphImage.mediaItemUrl}
          />
        )}
        <meta
          property="og:url"
          content={`https://www.mmklawfirm.com/${pageContext.slug}`}
        />
        <meta
          property="og:title"
          content={opengraphTitle ? opengraphTitle : title}
        />
        <meta property="og:description" content={metaDesc ? metaDesc : ""} />
        {pageContext.seo.opengraphImage !== null && (
          <meta
            property="og:image"
            content={pageContext.seo.opengraphImage.mediaItemUrl}
          />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://www.mmklawfirm.com/${pageContext.slug}`}
        />
        <meta
          name="twitter:title"
          content={
            twitterTitle
              ? twitterTitle
              : opengraphTitle
              ? opengraphTitle
              : title
          }
        />
        <meta
          name="twitter:description"
          content={
            twitterDescription ? twitterDescription : metaDesc ? metaDesc : ""
          }
        />
        {pageContext.seo.opengraphImage != null ||
          (pageContext.seo.twitterImage != null && (
            <meta
              name="twitter:image"
              content={
                pageContext.seo.twitterImage
                  ? pageContext.seo.twitterImage.mediaItemUrl
                  : pageContext.seo.opengraphImage
                  ? pageContext.seo.opengraphImage.mediaItemUrl
                  : ""
              }
            />
          ))}
      </Helmet>
      {page.featuredImage !== null && (
        <HeroSection
          fluid={page.featuredImage.node.imageFile.childImageSharp.fluid}
        >
          <Wrapper>
            <BannerSubtitle1Container>
              {bannerSubtitle1} <Line />
            </BannerSubtitle1Container>
            <BannerTitleContainer>{bannerTitle}</BannerTitleContainer>
            <BannerSubtitle2Container>
              <Line2 /> {bannerSubtitle2}
            </BannerSubtitle2Container>
            {breakpoints.md ? (
              <>
                <RedRectangularButton to="/#homeForm">
                  Get Your Free Consultation
                </RedRectangularButton>
              </>
            ) : null}
          </Wrapper>
        </HeroSection>
      )}
      <ContentSidebarContainer>
        <InteriorContent>
          <HeaderSection>
            <h3>{h3}</h3>
            <h1>{h1}</h1>
            <LineBottom>
              {breakpoints.sm ? (
                <>
                  <Img fluid={data.file.childImageSharp.fluid} />
                </>
              ) : (
                <img className="underline" src={underline} />
              )}
            </LineBottom>
          </HeaderSection>
          <p dangerouslySetInnerHTML={{ __html: firstParagraph }} />
          {page.title !== "Maggie Grasso" && <VideoPopup />}

          <div dangerouslySetInnerHTML={{ __html: restOfContent }} />
        </InteriorContent>
        <Sidebar />
      </ContentSidebarContainer>
      <WhatOurClientsAreSaying />
      <Maps />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        content
        featuredImage {
          node {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1682, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "underline.png" }) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
        fixed(width: 660, height: 18, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
